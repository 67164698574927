import React from 'react';
import Layout from './Layout';

function Download() {
  return (
<Layout>
        <section
            className="relative h-full bg-cover bg-center bg-no-repeat flex flex-row items-start px-8 py-20"
        >
      <div className="container mx-auto px-6 lg:px-8 pt-10">
        <h2 className="text-4xl font-bold text-center text-goldAccent mb-8">Download</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Game Client */}
          <div className="bg-black bg-opacity-85 p-6 rounded-lg shadow-lg hover:bg-black transition duration-300">
            <img src="./client_icon.png" alt="Game Client" className="h-20 mx-auto mb-4" />
            <h3 className="text-2xl font-semibold text-center mb-4">Game Client</h3>
            <p className="text-center mb-6">
              Download the full game client to start playing right away. Ensure you have enough space.
            </p>
            <div className="flex justify-center">
              <a
                href="/downloads/client.zip"
                className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-md text-lg font-medium"
                download
                >
                Download Client
              </a>
            </div>
          </div>

          {/* Patches */}
          <div className="bg-black bg-opacity-85 p-6 rounded-lg shadow-lg hover:bg-black transition duration-300">
            <img src="./patch_icon.png" alt="Patches" className="h-20 mx-auto mb-4" />
            <h3 className="text-2xl font-semibold text-center mb-4">Patches</h3>
            <p className="text-center mb-6">
              Keep your game up to date by downloading the latest patches. Easy to apply!
            </p>
            <div className="flex justify-center">
              <a
                href="/downloads/patch.zip"
                className="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded-md text-lg font-medium"
                download
              >
                Download Patch
              </a>
            </div>
          </div>

          {/* Launcher */}
          <div className="bg-black bg-opacity-85 p-6 rounded-lg shadow-lg hover:bg-black transition duration-300">
            <img src="./launcher_icon.png" alt="Launcher" className="h-20 mx-auto mb-4" />
            <h3 className="text-2xl font-semibold text-center mb-4">Launcher</h3>
            <p className="text-center mb-6">
              Use the official launcher to manage updates and ensure you are always playing the latest version.
            </p>
            <div className="flex justify-center">
              <a
                href="/downloads/launcher.exe"
                className="bg-red-600 hover:bg-red-700 text-white px-6 py-2 rounded-md text-lg font-medium"
                download
                >
                Download Launcher
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
</Layout>
  );
}

export default Download;
