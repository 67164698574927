// CountdownTimer.js
import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / (1000 * 60)) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="flex justify-center items-center space-x-4 bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600 bg-opacity-10 p-6 rounded-lg shadow-lg text-white backdrop-blur-md">
      {timeLeft.days >= 0 ? (
        <>
          <div className="text-center">
            <span className="text-2xl font-bold">{timeLeft.days}</span>
            <p className="text-xs">Days</p>
          </div>
          <span className="text-2xl font-semibold">:</span>
          <div className="text-center">
            <span className="text-2xl font-bold">{timeLeft.hours}</span>
            <p className="text-xs">Hours</p>
          </div>
          <span className="text-2xl font-semibold">:</span>
          <div className="text-center">
            <span className="text-2xl font-bold">{timeLeft.minutes}</span>
            <p className="text-xs">Minutes</p>
          </div>
          <span className="text-2xl font-semibold">:</span>
          <div className="text-center">
            <span className="text-2xl font-bold">{timeLeft.seconds}</span>
            <p className="text-xs">Seconds</p>
          </div>
        </>
      ) : (
        <p className="text-xl font-bold">Server Launched!</p>
      )}
    </div>
  );
};

export default CountdownTimer;
