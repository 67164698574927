import React from 'react';
import Layout from './Layout';

function Support() {
  return (
<Layout>
    <section className="relative h-full bg-cover bg-center bg-no-repeat flex flex-row items-start px-8 py-20 top-10">
      <div className="container mx-auto px-6 lg:px-8">
        <h2 className="text-4xl font-bold text-center text-goldAccent mb-8">Support</h2>

        {/* FAQs Section */}
        <div className="mb-12">
          <h3 className="text-3xl font-semibold mb-6">Frequently Asked Questions (FAQs)</h3>
          <div className="space-y-4">
            {/* FAQ Item 1 */}
            <details className="bg-gray-800 p-4 rounded-lg shadow-lg">
              <summary className="cursor-pointer text-lg font-medium">How do I download the game?</summary>
              <p className="mt-2 text-sm text-gray-300">
                You can download the game client from our <a href="/downloads" className="text-blue-400 underline">Download page</a>. Ensure that you meet the minimum system requirements.
              </p>
            </details>

            {/* FAQ Item 2 */}
            <details className="bg-gray-800 p-4 rounded-lg shadow-lg">
              <summary className="cursor-pointer text-lg font-medium">How can I recover my account?</summary>
              <p className="mt-2 text-sm text-gray-300">
                If you forgot your account details, you can use the recovery tools on the <a href="/account-recovery" className="text-blue-400 underline">Account Recovery page</a>.
              </p>
            </details>

            {/* FAQ Item 3 */}
            <details className="bg-gray-800 p-4 rounded-lg shadow-lg">
              <summary className="cursor-pointer text-lg font-medium">What are the system requirements?</summary>
              <p className="mt-2 text-sm text-gray-300">
                You can check the system requirements for the game on our <a href="/system-requirements" className="text-blue-400 underline">System Requirements page</a>.
              </p>
            </details>
          </div>
        </div>

        {/* Contact Form Section */}
        <div className="mb-12">
          <h3 className="text-3xl font-semibold mb-6">Contact Us</h3>
          <form action="/submit-ticket" method="POST" className="bg-gray-800 p-6 rounded-lg shadow-lg space-y-6">
            {/* Name Input */}
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-300">Name</label>
              <input
                type="text"
                name="name"
                id="name"
                className="mt-1 block w-full p-3 bg-gray-900 border-gray-600 rounded-md text-white"
                required
                />
            </div>

            {/* Email Input */}
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-300">Email</label>
              <input
                type="email"
                name="email"
                id="email"
                className="mt-1 block w-full p-3 bg-gray-900 border-gray-600 rounded-md text-white"
                required
                />
            </div>

            {/* Message Input */}
            <div>
              <label htmlFor="message" className="block text-sm font-medium text-gray-300">Message</label>
              <textarea
                name="message"
                id="message"
                rows="6"
                className="mt-1 block w-full p-3 bg-gray-900 border-gray-600 rounded-md text-white"
                required
                ></textarea>
            </div>

            {/* Submit Button */}
            <div className="flex justify-center">
              <button
                type="submit"
                className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-md text-lg font-medium"
                >
                Submit Ticket
              </button>
            </div>
          </form>
        </div>

        {/* Additional Support Resources */}
        <div>
          <h3 className="text-3xl font-semibold mb-6">Additional Support</h3>
          <ul className="space-y-4">
            <li>
              <a href="/forum" className="block bg-gray-800 p-4 rounded-lg shadow-lg hover:bg-gray-700 transition duration-300">
                Visit our Forum for community support
              </a>
            </li>
            <li>
              <a href="/live-chat" className="block bg-gray-800 p-4 rounded-lg shadow-lg hover:bg-gray-700 transition duration-300">
                Live Chat Support
              </a>
            </li>
            <li>
              <a href="/knowledge-base" className="block bg-gray-800 p-4 rounded-lg shadow-lg hover:bg-gray-700 transition duration-300">
                Browse the Knowledge Base for guides and help
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
</Layout>
  );
}

export default Support;
