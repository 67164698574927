import React, { useEffect } from 'react';
import Layout from "./Layout";
import './ServerInfo.css';

import Icon1 from './icons/icon1.png'; 

function ServerInfo() {
  useEffect(() => {
    const sections = document.querySelectorAll(".section-offset");
    const observerOptions = {
      root: null,
      rootMargin: "-50% 0px -50% 0px",
      threshold: 0,
    };
  
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        const id = entry.target.getAttribute("id");
        const link = document.querySelector(`.menu-item[href="#${id}"]`);
        
        if (entry.isIntersecting) {
          document.querySelectorAll('.menu-item').forEach(item => item.classList.remove('highlighted'));
          link?.classList.add('highlighted');
        }
      });
    }, observerOptions);
  
    sections.forEach(section => observer.observe(section));
  
    return () => observer.disconnect();
  }, []);
  

  const handleMenuClick = (e, id) => {
    e.preventDefault();
    const targetElement = document.getElementById(id);
    const offset = 100; // Adjust offset to match header height or padding
    const elementPosition = targetElement.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  };

  return (
    <Layout>
      <section className="server-info-page relative h-full w-screen bg-cover bg-center bg-no-repeat flex flex-row items-start py-20">
      <div className="max-w-7xl w-full flex pt-16 mx-auto">
          {/* Left Menu */}
          <div className="sidebar-items w-1/4 sticky-menu bg-black bg-opacity-50 p-2 md:p-6 rounded-lg">
            <ul className="space-y-4 text-white text-lg">
              <li><a href="#overview" onClick={(e) => handleMenuClick(e, 'overview')} className="menu-item">Server Overview</a></li>
              <li><a href="#rates" onClick={(e) => handleMenuClick(e, 'rates')} className="menu-item">Rates</a></li>
              <li><a href="#buffs-skills" onClick={(e) => handleMenuClick(e, 'buffs-skills')} className="menu-item">Buffs & Skills</a></li>
              <li><a href="#items" onClick={(e) => handleMenuClick(e, 'items')} className="menu-item">Items</a></li>
              <li><a href="#gameplay" onClick={(e) => handleMenuClick(e, 'gameplay')} className="menu-item">Gameplay</a></li>
              <li><a href="#professions" onClick={(e) => handleMenuClick(e, 'professions')} className="menu-item">Subclass</a></li>
              <li><a href="#siege" onClick={(e) => handleMenuClick(e, 'siege')} className="menu-item">Siege</a></li>
              <li><a href="#olympiad" onClick={(e) => handleMenuClick(e, 'olympiad')} className="menu-item">Olympiad</a></li>
              <li><a href="#catacombs" onClick={(e) => handleMenuClick(e, 'catacombs')} className="menu-item">Catacombs</a></li>
              <li><a href="#enchant" onClick={(e) => handleMenuClick(e, 'enchant')} className="menu-item">Enchant Rates</a></li>
              <li><a href="#npc" onClick={(e) => handleMenuClick(e, 'npc')} className="menu-item">NPC & Commands</a></li>
              <li><a href="#clans-party" onClick={(e) => handleMenuClick(e, 'clans-party')} className="menu-item">Clans</a></li>
              <li><a href="#raid-boss" onClick={(e) => handleMenuClick(e, 'raid-boss')} className="menu-item">Raid Boss</a></li>
              <li><a href="#epic-boss" onClick={(e) => handleMenuClick(e, 'epic-boss')} className="menu-item">Epic Boss</a></li>
              <li><a href="#augmentation" onClick={(e) => handleMenuClick(e, 'augmentation')} className="menu-item">Augmentation</a></li>
              <li><a href="#voice-commands" onClick={(e) => handleMenuClick(e, 'voice-commands')} className="menu-item">Voice Commands</a></li>
            </ul>
          </div>

          {/* Right Content Area */}
          <div className="w-full md:w-3/4 md:pl-8 space-y-12 p-4">
          <div id="overview" className="bg-black bg-opacity-30 p-6 rounded-lg section-offset">
          <div className="flex justify-between items-center border-b border-goldAccent mb-6">
            <h4 className="text-4xl font-semibold mb-4 text-goldAccent">
              Description Destiny x50
            </h4>
            <span className="text-lg text-white mb-4">Last Update: <span className="text-redmoonRed">November 4, 2024</span></span>
          </div>
            <p className="mb-2 text-white">Server Time Zone: <span className="text-redmoonRed">UTC +3</span></p>
            <p className="text-white">Long-term gameplay with carefully crafted content. No wipes.</p>
            <p className="text-white">No Pay to Win mechanics.</p>
            <p className="text-white">International community from America, Latin America, Asia, and Europe.</p>
            <p className="text-white">Suitable for solo players with numerous events, missions, achievements, bonuses, and rewards, as well as for clans with bonuses related to clan levels and reputation.</p>
            <p className="text-white">Special game client version based on Lineage 2 Classic with a more optimized game engine, modern interface, mounts, agathions, cloaks, and achievement systems.</p>

            {/* <!-- Additional Features --> */}
            <h3 className="text-2xl font-semibold mt-6 mb-2 text-goldAccent">Additional Features</h3>
            <ul className="list-inside text-white">
              <li>Autolearn skills up to level 77 (level 78+ skills require a book).</li>
              <li>Auto loot.</li>
              <li>Shift + click on monsters to see the droplist.</li>
              <li>Offline shop (duration 2 days before being kicked).</li>
              <li>Auto Augment Weapon by right-clicking on the life stone.</li>
              <li>Inventory slots: 150.</li>
              <li>Magic critical rate cap: 50%.</li>
              <li>Magic critical damage: x3.</li>
            </ul>
          </div>

          {/* <!-- Rates --> */}
          <div id="rates" className="bg-black bg-opacity-30 p-6 rounded-lg section-offset">
            <h2 className="text-4xl font-semibold mb-4 text-goldAccent">Server Rates</h2>

            <div className="flex flex-wrap justify-between">
              {/* <!-- Level Range 1 - 40--> */}
              <div className="w-1/4 min-w-[150px] max-w-[180px] p-3 text-center border border-goldAccent bg-goldAccent bg-opacity-35 rounded-lg m-2">
                <div className="text-lg font-bold text-white border-b-2 mb-2 border-redmoonRed">Lv. 1-40</div>
                
                <table className="table-auto w-full text-white text-sm">
                  <thead>
                    <tr>
                      <th className="text-left">Type</th>
                      <th>Rate</th>
                      <th>Without VIP</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-left">EXP/SP</td>
                      <td>x10</td>
                      <td>x7</td>
                    </tr>
                    <tr>
                      <td className="text-left">Adena</td>
                      <td>x3</td>
                      <td>x2</td>
                    </tr>
                    <tr>
                      <td className="text-left">Drop</td>
                      <td>x3</td>
                      <td>x2</td>
                    </tr>
                    <tr>
                      <td className="text-left">Spoil</td>
                      <td>x3</td>
                      <td>x2</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* <!-- Level Range 40 - 61 --> */}
              <div className="w-1/4 min-w-[150px] max-w-[180px] p-3 text-center border border-goldAccent bg-goldAccent bg-opacity-35 rounded-lg m-2">
                <div className="text-lg font-bold text-white border-b-2 mb-2 border-redmoonRed">Lv. 40-61</div>
                
                <table className="table-auto w-full text-white text-sm">
                  <thead>
                    <tr>
                      <th className="text-left">Type</th>
                      <th>Rate</th>
                      <th>Without VIP</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-left">EXP/SP</td>
                      <td>x10</td>
                      <td>x7</td>
                    </tr>
                    <tr>
                      <td className="text-left">Adena</td>
                      <td>x3</td>
                      <td>x2</td>
                    </tr>
                    <tr>
                      <td className="text-left">Drop</td>
                      <td>x3</td>
                      <td>x2</td>
                    </tr>
                    <tr>
                      <td className="text-left">Spoil</td>
                      <td>x3</td>
                      <td>x2</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* <!-- Level Range 61 - 76 --> */}
              <div className="w-1/4 min-w-[150px] max-w-[180px] p-3 text-center border border-goldAccent bg-goldAccent bg-opacity-35 rounded-lg m-2">
                <div className="text-lg font-bold text-white border-b-2 mb-2 border-redmoonRed">Lv. 61-76</div>
                
                <table className="table-auto w-full text-white text-sm">
                  <thead>
                    <tr>
                      <th className="text-left">Type</th>
                      <th>Rate</th>
                      <th>Without VIP</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-left">EXP/SP</td>
                      <td>x10</td>
                      <td>x7</td>
                    </tr>
                    <tr>
                      <td className="text-left">Adena</td>
                      <td>x3</td>
                      <td>x2</td>
                    </tr>
                    <tr>
                      <td className="text-left">Drop</td>
                      <td>x3</td>
                      <td>x2</td>
                    </tr>
                    <tr>
                      <td className="text-left">Spoil</td>
                      <td>x3</td>
                      <td>x2</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* <!-- Level Range 76-80 --> */}
              <div className="w-1/4 min-w-[150px] max-w-[180px] p-3 text-center border border-goldAccent bg-goldAccent bg-opacity-35 rounded-lg m-2">
                <div className="text-lg font-bold text-white border-b-2 mb-2 border-redmoonRed">Lv. 76-80</div>
                
                <table className="table-auto w-full text-white text-sm">
                  <thead>
                    <tr>
                      <th className="text-left">Type</th>
                      <th>Rate</th>
                      <th>Without VIP</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-left">EXP/SP</td>
                      <td>x10</td>
                      <td>x7</td>
                    </tr>
                    <tr>
                      <td className="text-left">Adena</td>
                      <td>x3</td>
                      <td>x2</td>
                    </tr>
                    <tr>
                      <td className="text-left">Drop</td>
                      <td>x3</td>
                      <td>x2</td>
                    </tr>
                    <tr>
                      <td className="text-left">Spoil</td>
                      <td>x3</td>
                      <td>x2</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="mt-6">
              <h5 className="text-xl font-semibold text-goldAccent">Other Rates</h5>
              <p className="text-lg text-white">RB EXP/SP: <span className="font-semibold">x5</span> (x3 without vote reward or VIP)</p>
              <p className="text-lg text-white">RB Drop: <span className="font-semibold">x3</span> (x2 without vote reward or VIP)</p>
              <p className="text-lg text-white">Epic RB Drop: <span className="font-semibold">x1</span> (No bonuses available)</p>
            </div>
          </div>

            <div id="buffs-skills" className="bg-black bg-opacity-30 p-6 rounded-lg section-offset">
              <h2 className="text-4xl font-semibold mb-4 text-goldAccent">Buffs & Skills</h2>
              <ul className="text-white space-y-2">
                <li>Number of Buff Slots: <span className="text-redmoonRed">24+4</span></li>
                <li>Buff Duration: <span className="text-redmoonRed">2 hours</span></li>
                <li>Skills: Auto-learning</li>
                <li>Unstuck: <span className="text-redmoonRed">15 seconds</span></li>
                <li>Flames of Invincibility: Works like on Gracia Final chronicles (effect disappears on any attack)</li>
                <li>Canceling Skill: Canceled buffs do not return, chance 35%</li>
                <li>Mana Burn and Mana Storm: Critical chance 30% </li>
              </ul>
            </div>

            {/* <!-- Items --> */}
            <div id="items" className="bg-black bg-opacity-30 p-6 rounded-lg section-offset">
              <h2 className="text-4xl font-semibold mb-4 text-goldAccent">Items</h2>

              <ul className="space-y-3 text-white text-lg">
                <li>
                  <span className="font-semibold text-goldAccent">Mana Potions:</span> +500 mana, reuse time 2s
                </li>
                <li>
                  <span className="font-semibold text-goldAccent">Shadow Tokens:</span> Exchange for shadow weapons, reward from 1st, 2nd & 3rd profession
                </li>
                <li>
                  <span className="font-semibold text-goldAccent">Festival Adena (FA):</span> Exchange in shop, reward for events, siege, missions, bonuses
                </li>
                <li>
                  <span className="font-semibold text-goldAccent">Coin of Luck (COL):</span> Donate coin
                </li>
                <li>
                  <span className="font-semibold text-goldAccent">Top Coin:</span> Exchange in shop, reward for voting every 12-24 hours by using voice command <code>.vote</code>
                </li>
                <li>
                  <span className="font-semibold text-goldAccent">Vote Buff Coin:</span> Exchange for 3 hours free premium buff, cost 1 Top Coin
                </li>
                <li>
                  <span className="font-semibold text-goldAccent">Champion Coin:</span> Single premium buff
                </li>
                <li>
                  <span className="font-semibold text-goldAccent">Rune (Premium, EXP/SP, Adena, Drop, Spoil):</span> Increases base rates by 50%, reward for events, missions, bonuses
                </li>
                <li>
                  <span className="font-semibold text-goldAccent">Ring of Core:</span> Gives additional bonus +8% M. attack & +3% P. defense <span className="text-redmoonRed">[NEW]</span>
                </li>
                <li>
                  <span className="font-semibold text-goldAccent">Earring of Orfen:</span> Gives additional bonus +4% P. attack & +3% M. defense <span className="text-redmoonRed">[NEW]</span>
                </li>
              </ul>
            </div>

            {/* <!-- Gameplay --> */}
            <div id="gameplay" className="bg-black bg-opacity-30 p-6 rounded-lg section-offset">
              <h2 className="text-4xl font-semibold mb-4 text-goldAccent">Gameplay</h2>

              <ul className="space-y-3 text-white text-lg">
                <li>
                  <span className="font-semibold text-goldAccent">Every new character:</span> Receive TOP NG weapon and armor, 50,000 adena, Scrolls of Escape, and 10 Mana Potions
                </li>
                <li>
                  <span className="font-semibold text-goldAccent">Dualbox:</span> Maximum 4 windows
                </li>
                <li>
                  <span className="font-semibold text-goldAccent">Offline Trade:</span> Just start Private Store and close game client
                </li>
                <li>
                  <span className="font-semibold text-goldAccent">Weight:</span> Weight penalty is disabled. Maximum count of items in the inventory increased to 200
                </li>
                <li>
                  <span className="font-semibold text-goldAccent">Tyrannosaur:</span> Life stone drop: 50%
                </li>
                <li>
                  <span className="font-semibold text-goldAccent">Achievements:</span> Various with rewards
                </li>
                <li>
                  <span className="font-semibold text-goldAccent">Events:</span> Regularly organized events for the community
                </li>
              </ul>
            </div>


            {/* <!-- Profession / Subclass / Nobless --> */}
            <div id="professions" className="bg-black bg-opacity-30 p-6 rounded-lg section-offset">
              <h2 className="text-4xl font-semibold mb-4 text-goldAccent">Profession / Subclass / Nobless</h2>

              <ul className="space-y-3 text-white text-lg">
                {/* 1st Class */}
                <li>
                  <span className="font-semibold text-goldAccent">1st Class:</span> FREE & GIFT + 10 Shadow Tokens + 20 Mana Potions
                </li>
                {/* 2nd Class */}
                <li>
                  <span className="font-semibold text-goldAccent">2nd Class:</span> FREE & GIFT + 20 Shadow Tokens + 50 Mana Potions
                </li>
                {/* 3rd Class */}
                <li>
                  <span className="font-semibold text-goldAccent">3rd Class:</span> Costs 50,000,000 Adena & GIFT + 40 Shadow Tokens + 
                  <img src="/path/to/image.png" alt="Secret Book of Giant" className="inline-block w-6 h-6 mx-2" />1 Secret Book of Giant
                </li>
                {/* Subclass */}
                <li>
                  <span className="font-semibold text-goldAccent">Subclass:</span> Without quest - Maximum 7 subclasses <span className="text-redmoonRed">[NEW]</span>
                </li>
                {/* Nobless */}
                <li>
                  <span className="font-semibold text-goldAccent">Nobless:</span> 
                  <img src="/path/to/image.png" alt="Lunargent" className="inline-block w-6 h-6 mx-1" />Lunargent & 
                  <img src="/path/to/image.png" alt="Hellfire Oil" className="inline-block w-6 h-6 mx-1" />Hellfire Oil available in Shop & added alternative way to complete "Noblesse Quest". You need to attack Flame of Splendor Barakiel to get the Staff of Goddess Rain Song fragment. 15 fragments are automatically exchanged for the quest item "Staff of Goddess Rain Song", which is needed to complete the quest (only one fragment per Raid Boss). <span className="text-redmoonRed">[NEW]</span>
                </li>
              </ul>
            </div>

            {/* <!-- Siege --> */}
            <div id="siege" className="bg-black bg-opacity-30 p-6 rounded-lg section-offset">
              <h2 className="text-4xl font-semibold mb-4 text-goldAccent">Siege</h2>

              <ul className="space-y-3 text-white text-lg">
                {/* Siege Period */}
                <li>
                  <span className="font-semibold text-goldAccent">Period:</span> Every Saturday, from 20:00 to 22:00
                </li>
                {/* Siege Locations */}
                <li>
                  <span className="font-semibold text-goldAccent">Innadril / Goddard / Rune / Schuttgart:</span> Siege every 2 weeks
                </li>
                <li>
                  <span className="font-semibold text-goldAccent">Gludio / Dion / Giran / Oren / Aden:</span> Siege every 2 weeks
                </li>
                {/* Rewards */}
                <li>
                  <span className="font-semibold text-goldAccent">Rewards:</span> Additional stats for clan members who own a castle:
                  <ul className="list-disc list-inside ml-6">
                    <li>+3% P. Atk./M. Atk.</li>
                    <li>+2% CP/HP/MP</li>
                    <li>+1% Atk. Speed & Cast Speed</li>
                  </ul>
                  <p className="ml-6 text-white">
                    <span className="font-semibold">Note:</span> These bonuses do not work in the Olympiad, PvP, on Raid, and Epic Bosses.
                  </p>
                </li>
                {/* After Siege Rewards */}
                <li>
                  <span className="font-semibold text-goldAccent">After Siege:</span> The owner of the castle gets Festival Adena (FA) <span className="text-redmoonRed">[NEW]</span>
                </li>
              </ul>
            </div>


            {/* <!-- Olympiad --> */}
            <div id="olympiad" className="bg-black bg-opacity-30 p-6 rounded-lg section-offset">
              <h2 className="text-4xl font-semibold mb-4 text-goldAccent">Olympiad</h2>

              <ul className="space-y-3 text-white text-lg">
                {/* Olympiad Period */}
                <li>
                  <span className="font-semibold text-goldAccent">Period:</span> 1 week, starts every Monday from 18:00 to 00:00
                </li>
                {/* Enchant Grade */}
                <li>
                  <span className="font-semibold text-goldAccent">Maximum grade of enchant:</span> +6
                </li>
                {/* Registration */}
                <li>
                  <span className="font-semibold text-goldAccent">Registration:</span> Allowed only in non-class mode
                </li>
                {/* Noblesse Status */}
                <li>
                  <span className="font-semibold text-goldAccent">Status:</span> Each participant must have a noblesse status
                </li>
                {/* Minimum Players */}
                <li>
                  <span className="font-semibold text-goldAccent">Minimum number of players to register:</span> 5
                </li>
                {/* Combat Limit */}
                <li>
                  <span className="font-semibold text-goldAccent">Limit of Combats:</span> Unlimited (until the end of points)
                </li>
                {/* Start Buffs */}
                <li>
                  <span className="font-semibold text-goldAccent">Start buff's:</span> Custom
                </li>
                {/* Arenas */}
                <li>
                  <span className="font-semibold text-goldAccent">Arenas:</span> Custom
                </li>
                {/* Rewards */}
                <li>
                  <span className="font-semibold text-goldAccent">Rewards:</span> Blessed Scrolls of Enchant, Books & other items for gate pass and missions
                </li>
              </ul>
            </div>


            {/* <!-- Catacombs (Seven Signs) --> */}
            <div id="catacombs" className="bg-black bg-opacity-30 p-6 rounded-lg section-offset">
              <h2 className="text-4xl font-semibold mb-4 text-goldAccent">Catacombs (Seven Signs) <span className="text-redmoonRed">[NEW]</span></h2>

              <ul className="space-y-3 text-white text-lg">
                {/* Quest Period */}
                <li>
                  <span className="font-semibold text-goldAccent">Quest Period:</span> Monday from 18:00 to Friday 6:00
                </li>
                {/* Reward Period */}
                <li>
                  <span className="font-semibold text-goldAccent">Reward Period:</span> Friday from 6:00 to Monday 17:45
                </li>
                {/* Dawn or Dusk NPCs - Monday to Friday */}
                <li>
                  <span className="font-semibold text-goldAccent">Dawn or Dusk NPCs (Monday to Friday):</span> To join catacombs and necropolis, you need to register with the Dawn or Dusk NPCs.
                </li>
                {/* Dawn or Dusk NPCs - Friday to Monday */}
                <li>
                  <span className="font-semibold text-goldAccent">Dawn or Dusk NPCs (Friday to Monday):</span> To join catacombs and necropolis, you must be on the victorious side (Dawn or Dusk).
                </li>
                {/* Mammon Section */}
                <li>
                  <span className="font-semibold text-goldAccent">Mammon Section in the Shop:</span> From Friday to Monday, all registered players in Seven Signs can use it regardless of the 7s results.
                </li>
                {/* 1st Mammon */}
                <li>
                  <span className="font-semibold text-goldAccent">1st Mammon:</span> Possible to exchange A-grade equipment.
                </li>
                {/* 2nd Mammon */}
                <li>
                  <span className="font-semibold text-goldAccent">2nd Mammon:</span> Possible to exchange A and S-grade equipment.
                </li>
              </ul>
            </div>


            <div id="enchant" className="bg-black bg-opacity-30 p-6 rounded-lg section-offset">
              <h2 className="text-4xl font-semibold mb-4 text-goldAccent">Enchant Rates</h2>
              <ul className="text-white space-y-2">
                <li>Weapon Enchant Rates: <span className="text-redmoonRed">60%</span></li>
                <li>Armor Enchant Rates: <span className="text-redmoonRed">60%</span></li>
                <li>Jewelry Enchant Rates: <span className="text-redmoonRed">40%</span></li>
                <li>Enchant Scrolls: <span className="text-redmoonRed">Available through various means</span></li>
              </ul>
            </div>

            {/* <!-- NPC Section --> */}
              <div id="npc" className="bg-black bg-opacity-30 p-6 rounded-lg section-offset">
                <h2 className="text-4xl font-semibold mb-4 text-goldAccent">NPC</h2>

                <ul className="space-y-3 text-white text-lg">
                  {/* Buffer */}
                  <li>
                    <span className="font-semibold text-goldAccent">Buffer:</span> All buffs, including pet buffs, resistances, and more. Duration 2 hours.
                  </li>
                  {/* Shop */}
                  <li>
                    <span className="font-semibold text-goldAccent">Shop:</span> Up to B-grade armors, jewels, and weapons, exchange of fangs for resources, AA for stones, misc items, and more.
                  </li>
                  {/* Shop - Event Section */}
                  <li>
                    <span className="font-semibold text-goldAccent">Shop - Event Section:</span> Blessed scrolls of enchant, books, life stones, soul crystals, accessories, and more.
                  </li>
                  {/* Shop - Top Coin Section */}
                  <li>
                    <span className="font-semibold text-goldAccent">Shop - Top Coin Section:</span> Accessories, Premium buff for 3h, Runes for 6h, FA, and more.
                  </li>
                  {/* Shop - Donate Section */}
                  <li>
                    <span className="font-semibold text-goldAccent">Shop - Donate Section:</span> Accessories - hats from 5 COLs and suite, agathion, cloak, mount from 25 COLs, title and name color from 5 COLs, Premium Account, and Prime Subscription from 3 COLs.
                  </li>
                  {/* Shop - Mammon */}
                  <li>
                    <span className="font-semibold text-goldAccent">Shop - Mammon:</span> Available from Friday 18:00 to Monday 18:00.
                  </li>
                  {/* Market */}
                  <li>
                    <span className="font-semibold text-goldAccent">Market:</span> Live market, private store including off-trade, with convenient filters.
                  </li>
                  {/* Global Gatekeeper */}
                  <li>
                    <span className="font-semibold text-goldAccent">Global Gatekeeper (GK):</span> Teleport to Towns, Catacombs, Necropolis, Dream Boss, Primeval Area.
                  </li>
                  {/* Class Master */}
                  <li>
                    <span className="font-semibold text-goldAccent">Class Master:</span> 1st, 2nd, 3rd Profession, Subclass, Delevel - costs 50,000 adena per level.
                  </li>
                </ul>
              </div>


            {/* <!-- Clans / Party Section --> */}
            <div id="clans-party" className="bg-black bg-opacity-30 p-6 rounded-lg section-offset">
              <h2 className="text-4xl font-semibold mb-4 text-goldAccent">Clans / Party</h2>

              <ul className="space-y-3 text-white text-lg">
                {/* Number of players in one clan */}
                <li>
                  <span className="font-semibold text-goldAccent">Number of players in one clan:</span> 120
                </li>
                {/* Number of clans in the alliance */}
                <li>
                  <span className="font-semibold text-goldAccent">Number of clans in the alliance:</span> 5
                </li>
                {/* Clan penalties */}
                <li>
                  <span className="font-semibold text-goldAccent">Clan penalties:</span> Disabled
                </li>
                {/* Number of players in one party */}
                <li>
                  <span className="font-semibold text-goldAccent">Number of players in one party:</span> 9
                </li>
                {/* Maximal limits from clan and alliance on Epic zones */}
                <li>
                  <span className="font-semibold text-goldAccent">Maximal limits from clan and alliance on Epic zones:</span> 7 CP (63 players) <span className="text-redmoonRed">[NEW]</span>
                </li>
              </ul>
            </div>


            {/* <!-- Raid Boss Section --> */}
            <div id="raid-boss" className="bg-black bg-opacity-30 p-6 rounded-lg section-offset">
              <h2 className="text-4xl font-semibold mb-4 text-goldAccent">Raid Boss</h2>

              <ul className="space-y-3 text-white text-lg">
                {/* Common Raids */}
                <li>
                  <span className="font-semibold text-goldAccent">Common Raids:</span> 12h + 30m random
                </li>
                {/* Barakiel */}
                <li>
                  <span className="font-semibold text-goldAccent">Barakiel:</span> 5h + 15m random
                </li>
                {/* Varka / Ketra */}
                <li>
                  <span className="font-semibold text-goldAccent">Varka / Ketra:</span> 8h + 30m random
                </li>
                {/* High Priest Van Halter / Uruka / Lord of Splendor Anays */}
                <li>
                  <span className="font-semibold text-goldAccent">High Priest Van Halter / Uruka / Lord of Splendor Anays:</span> 6h + 30m random
                </li>
                {/* Fixed Respawn Time */}
                <li>
                  <span className="font-semibold text-goldAccent">Dream Boss:</span> 20:00* (resp. 1 day)
                </li>
              </ul>
            </div>


            {/* <!-- Epic Boss Section --> */}
            <div id="epic-boss" className="bg-black bg-opacity-30 p-6 rounded-lg section-offset">
              <h2 className="text-4xl font-semibold mb-4 text-goldAccent">Epic Boss</h2>

              <ul className="space-y-3 text-white text-lg">
                {/* Valakas */}
                <li>
                  <span className="font-semibold text-goldAccent">Valakas (lvl 85):</span> 11 days + 1h random, entry time 30m, time for kill 2h
                </li>
                {/* Antharas */}
                <li>
                  <span className="font-semibold text-goldAccent">Antharas (lvl 80):</span> 8 days + 1h random, entry time 30m, time for kill 2h
                </li>
                {/* Baium */}
                <li>
                  <span className="font-semibold text-goldAccent">Baium (lvl 80):</span> 5 days + 2h random, time for kill 2h
                </li>
                {/* Zaken */}
                <li>
                  <span className="font-semibold text-goldAccent">Zaken (lvl 60):</span> 2 days + 30m random - Gates open at 12:00 AM game time! (off-like mechanics) (entry level in zone maximum 68)
                </li>
                {/* Core / Orfen */}
                <li>
                  <span className="font-semibold text-goldAccent">Core / Orfen (lvl 50):</span> 36h + 30m random (entry level in zone maximum 58)
                </li>
                {/* Queen Ant */}
                <li>
                  <span className="font-semibold text-goldAccent">Queen Ant (lvl 40):</span> 1 day + 30m random (entry level in zone maximum 46)
                </li>
                {/* Frintezza */}
                <li>
                  <span className="font-semibold text-goldAccent">Frintezza (lvl 85):</span> 2 days + 1h random, time for kill 2h, CC rules: minimum 3 parties, minimum 2 players in party
                </li>
              </ul>
            </div>

            {/* <!-- Augmentation Section --> */}
            <div id="augmentation" className="bg-black bg-opacity-30 p-6 rounded-lg section-offset">
              <h2 className="text-4xl font-semibold mb-4 text-goldAccent">Augmentation (Item Skills)</h2>
              
              <p className="text-lg text-white mb-2">
                It is possible to use an active and passive skill (the effect does not disappear after removing the weapon).
              </p>
              <p className="text-lg text-white mb-2">
                Augmented weapons are tradeable.
              </p>
              <p className="text-lg text-white mb-2">
                Duration of buffs from augmentation skills: <span className="font-semibold">20 minutes</span>, at the Olympiad - <span className="font-semibold">2 minutes</span>.
              </p>
              <p className="text-lg text-white mb-2">
                Chance to get a skill with Life Stones:
              </p>
              
              <ul className="list-disc pl-5 text-white text-lg">
                <li><span className="font-semibold">Top:</span> 12%</li>
                <li><span className="font-semibold">High:</span> 4%</li>
                <li><span className="font-semibold">Mid:</span> 3%</li>
                <li><span className="font-semibold">Simple:</span> 1%</li>
              </ul>

              <h3 className="text-2xl font-semibold mt-4 text-goldAccent">Augmentation Description (Item Skills)</h3>
              <ul className="list-disc pl-5 text-white text-lg mt-2">
                <li>Item Skill: <span className="font-semibold">Wild Magic - Passive (Level 10)</span> - 50% to the chance of a magical critical hit</li>
                <li>Item Skill: <span className="font-semibold">Wild Magic - Active (Level 10)</span> - 100% to the chance of a magical critical hit</li>
                <li>Item Skill: <span className="font-semibold">Empower - Passive (Level 10)</span> - 20% to the M. attack</li>
                <li>Item Skill: <span className="font-semibold">Empower - Active (Level 10)</span> - 20% to the M. attack</li>
                <li>Item Skill: <span className="font-semibold">Duel Might - Passive (Level 10)</span> - 19% to the P. attack in PvP</li>
                <li>Item Skill: <span className="font-semibold">Duel Might - Active (Level 10)</span> - 10% to the P. attack in PvP</li>
                <li>Item Skill: <span className="font-semibold">Might - Passive (Level 10)</span> - 10% to the P. attack</li>
                <li>Item Skill: <span className="font-semibold">Might - Active (Level 10)</span> - 10% to the P. attack</li>
                <li>Item Skill: <span className="font-semibold">Shield - Passive (Level 10)</span> - 10% to the P. defense</li>
                <li>Item Skill: <span className="font-semibold">Shield - Active (Level 10)</span> - 10% to the P. defense</li>
                <li>Item Skill: <span className="font-semibold">Magic Barrier - Passive (Level 10)</span> - 10% to the M. defense</li>
                <li>Item Skill: <span className="font-semibold">Magic Barrier - Active (Level 10)</span> - 20% to the M. defense</li>
                <li>Item Skill: <span className="font-semibold">Prayer - Passive (Level 10)</span> - 19% to the heal effect</li>
                <li>Item Skill: <span className="font-semibold">Prayer - Active (Level 10)</span> - 10% to the heal effect</li>
              </ul>
            </div>


            {/* <!-- Voice Commands Section --> */}
              <div id="voice-commands" className="bg-black bg-opacity-30 p-6 rounded-lg section-offset">
                <h2 className="text-4xl font-semibold mb-4 text-goldAccent">Voice Commands</h2>
                
                <ul className="list-disc pl-5 text-white text-lg">
                  <li><span className="font-semibold">.menu:</span> Various settings, similar to the "Cabinet" section in Alt+B</li>
                  <li><span className="font-semibold">.acp:</span> ACP function settings</li>
                  <li><span className="font-semibold">.lock:</span> Additional character protection with a PIN code</li>
                  <li><span className="font-semibold">.whoami:</span> Detailed characteristics of the character</li>
                  <li><span className="font-semibold">.vote:</span> Menu for voting for the server</li>
                  <li><span className="font-semibold">.epic:</span> Information about the revival of Epic Bosses</li>
                  <li><span className="font-semibold">.promo:</span> Activation of promotional codes issued for participation in promotions and events</li>
                  <li><span className="font-semibold">.classmaster:</span> Service of Professions, Subclasses & Delevel</li>
                  <li><span className="font-semibold">.relog:</span> Fast re-log</li>
                  <li><span className="font-semibold">.time:</span> Server time</li>
                  <li><span className="font-semibold">.dmjoin:</span> Registration for the Death Match event</li>
                  <li><span className="font-semibold">.lhjoin:</span> Registration for the Last Hero event</li>
                  <li><span className="font-semibold">.ctfjoin:</span> Registration for the Capture the Flag event</li>
                  <li><span className="font-semibold">.tvtjoin:</span> Registration for the Team vs Team event</li>
                  <li><span className="font-semibold">.leave:</span> Leave an event before it ends. (In this case, the reward will not be issued)</li>
                </ul>
              </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default ServerInfo;
