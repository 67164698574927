import React from 'react';
import { Link } from 'react-router-dom';

function Button({ to, children }) {
  return (
    <Link to={to} className="px-4 py-2 text-sm font-semibold text-white bg-black bg-opacity-65 hover:bg-yellow-600 rounded-md shadow-xl shadow-goldAccent">
      {children}
    </Link>
  );
}

export default Button;
