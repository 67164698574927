// src/components/Donation.js
import React from 'react';

function Donation() {
  return (
    <div>
      <h1 className="text-3xl font-bold text-goldAccent">Donation</h1>
      <p className="mt-4">Here you can manage donations and view donation status.</p>
    </div>
  );
}

export default Donation;
