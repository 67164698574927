import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Flag1 from './flags/flag1.png'; // English flag
import Flag2 from './flags/flag2.png'; // Russian flag
import Flag3 from './flags/flag3.png'; // Add more flags as needed

const LanguageDropdown = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedFlag, setSelectedFlag] = useState(Flag1); // Default selected flag
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const flags = [
    { src: Flag1, alt: 'English Flag' },
    { src: Flag2, alt: 'Russian Flag' },
    { src: Flag3, alt: 'Other Flag' } // Add more flags here
  ];

  const handleFlagSelect = (flag) => {
    setSelectedFlag(flag);
    setIsDropdownOpen(false); // Close the dropdown after selecting
  };

  return (
    <div className="relative inline-block text-left">
      <button
        type="button"
        className="inline-flex items-center justify-center gap-x-1.5 rounded-md bg-black bg-opacity-45 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm border hover:border hover:border-goldAccent"
        id="menu-button"
        aria-expanded={isDropdownOpen ? 'true' : 'false'}
        aria-haspopup="true"
        onClick={toggleDropdown}
      >
        <img src={selectedFlag} alt="Selected Flag" className="h-6 w-auto max-w-[36px] md:h-7 md:max-w-[48px] object-cover" />
        {/* <FontAwesomeIcon icon={faChevronDown} className="hidden -mr-1 h-5 w-5 text-gray-400" /> */} 
      </button>

      {isDropdownOpen && (
        <div
          className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
        >
          <div className="py-1 grid grid-cols-2 gap-2" role="none"> {/* Use grid to layout flags */}
            {flags.map((flag, index) => (
              <button
                key={index}
                className={`flex justify-center items-center p-2 rounded-md ${selectedFlag === flag.src ? 'bg-gray-200' : ''}`} // Highlight selected flag
                onClick={() => handleFlagSelect(flag.src)} // Set selected flag on click
                role="menuitem"
              >
                <img src={flag.src} alt={flag.alt} className="h-8 w-12" /> {/* Larger flags */}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageDropdown;
