import React from 'react';
import './ServerStatus.css'; // Separate CSS for animations
import SmallLogo from '../assets/dzeus/carnage.png'; // Path to the rectangular server logo
import { Link } from 'react-router-dom';

function ServerStatus() {
  return (
    <div className="server-status-container bg-black bg-opacity-60 border border-opacity-45 border-goldAccent p-4 rounded-lg shadow-lg flex items-center space-x-4">
      {/* Server Logo with Beating Animation */}
      <Link to="/serverinfo">
        <div className="logo-beat-container relative flex-shrink-0">
          {/* Rectangular Logo */}
          <img 
            src={SmallLogo} 
            alt="Server Logo" 
            className="server-logo animate-beat object-contain h-32 w-auto"  // Apply Tailwind classes here
          />

          {/* Particle Glow Effect */}
          <div className="particle-glow"></div>
        </div>
      </Link>

      {/* Server Status Info - Text on the right */}
      <div className="status-info text-right">
        <h2 className="text-3xl font-extrabold text-white">Carnage</h2>
        <div className="flex items-center space-x-2">
          {/* Online Indicator */}
          <div className="status-dot bg-green-500"></div>
          <span className="text-lg font-bold text-green-500">Online</span>
        </div>
        <div className="text-lg font-semibold text-goldAccent">Server: x50</div>
      </div>
    </div>
  );
}

export default ServerStatus;
