import React, { useEffect } from 'react';
import Layout from "./Layout";
import { Link } from 'react-router-dom';
import { FaDiscord, FaYoutube, FaFacebook } from 'react-icons/fa';
import ServerStatus from './ServerStatus';
import ServerStatus2 from './ServerStatus2';
import './Home.css';
import DonateForCoins from './DonateForCoins';

import Btn1 from '../assets/btn2.png';
import Btn2 from '../assets/btn5.png';
import Btn3 from '../assets/btn6.png';
import CountdownTimer from './CountdownTimer';

function Home() {
  useEffect(() => {
    const snowContainer = document.querySelector('.snow');
    const maxSnowflakes = 70; // Maximum number of snowflakes at a time
    let snowflakeCount = 0;
  
    function createSnowflake() {
      if (snowflakeCount >= maxSnowflakes) {
        // If we've reached the max number of snowflakes, exit early
        return;
      }
  
      const snowflake = document.createElement('div');
      snowflake.classList.add('snowflake');
  
      // Randomize size
      const size = Math.random() * 2 + 3; // size between 5 and 10
      snowflake.style.width = `${size}px`;
      snowflake.style.height = `${size}px`;
  
      // Position it randomly
      snowflake.style.left = `${Math.random() * window.innerWidth}px`;
  
      // Set falling duration (slow speed)
      snowflake.style.animationDuration = `${Math.random() * 15 + 15}s`; // between 15 and 30 seconds
      snowflake.style.opacity = Math.random(); // Random opacity
  
      snowContainer.appendChild(snowflake);
      snowflakeCount++; // Increment the count of snowflakes
  
      // Remove the snowflake after it falls and update count
      snowflake.addEventListener('animationend', () => {
        snowflake.remove();
        snowflakeCount--; // Decrement the count when snowflake is removed
      });
    }
  
    // Generate snowflakes at a slower rate
    const intervalId = setInterval(createSnowflake, 400); // Slower creation rate
  
    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Layout>
      {/* Snow Effect Container */}
      <div className="snow"></div>

      <section className="relative min-h-screen bg-cover bg-center bg-no-repeat pt-24 px-0">
        <div id="home" className="flex flex-grow flex-col justify-center items-center h-full text-white text-center">
          {/* Main Title Section */}
          <div className="flex flex-col lg:flex-row justify-between items-center w-full max-w-7xl bg-black bg-opacity-65 p-6 rounded-lg shadow-lg mb-8">
            
            {/* Server Status Components - Responsive Layout */}
            <div className="flex flex-col sm:flex-row lg:flex-col pl-6 items-center space-y-4 space-x-0 sm:space-y-0 sm:space-x-4 lg:space-y-4 lg:space-x-0">
              <ServerStatus />
              <ServerStatus2 />
            </div>
            
            {/* Text Section on the Right */}
            <div className="w-full lg:w-2/3 text-left lg:text-right pt-10">
              <h1 className="text-5xl font-bold mb-4 text-goldAccent">Welcome to L2 Destiny</h1>
              <p className="text-xl mb-8">
              The L2 Destiny team is thrilled to bring this amazing project to light! Time flies and our team works tirelessly 
                 to bring you the best of Lineage 2 Classic Interlude. This new start combines a balanced, 
                long-term mid-rate experience with dynamic features designed for comfort, depth, and immersion. Enjoy a unique Interlude 
                experience brought to life by the dedication of the Destiny Team.
              </p>
              <CountdownTimer targetDate="2025-01-01T00:00:00Z" />

              {/* Action Buttons */}
              <div className="flex justify-center lg:justify-end space-x-0 md:space-x-4 mt-6 mb-8">
                <Link to="/download">
                  <button
                    style={{ backgroundImage: `url(${Btn1})` }}
                    className="bg-cover h-24 w-52 bg-center bg-opacity-65 py-3 px-8 rounded-md text-white text-2xl hover:shadow-lg hover:scale-105 hover:text-shadow-lg transition-all duration-300 ease-in-out"
                  >
                    Play Now
                  </button>
                </Link>
                <Link to="/https://discord.com/your-link">
                  <button 
                    style={{ backgroundImage: `url(${Btn2})` }}
                    className="bg-cover h-24 w-52 bg-center bg-opacity-65 py-3 px-8 rounded-md text-white text-xl hover:shadow-lg hover:scale-105 transition-all duration-300 ease-in-out"
                  >
                    Join Community
                  </button>
                </Link>
              </div>
            </div>
          </div>

          {/* Container for News and General Info Section */}
          <div className="relative bg-transparent p-6 flex justify-center">
            <div className="flex flex-col sm:flex-row space-y-8 sm:space-y-0 sm:space-x-8 lg:space-x-16 2xl:space-x-32 w-full max-w-7xl">
              
              {/* News Section */}
              <div className="bg-black bg-opacity-85 text-white p-6 w-full sm:w-1/2 rounded-md shadow-lg">
                <h2 className="text-2xl font-bold mb-4">Main x50 New Start! </h2>
                <div className="border border-gray-600 p-4 rounded-md">
                  <h3 className="text-xl font-semibold">Open December 28 19:00 UTC+3</h3>
                  <p className="mb-2">
                    Modern Classic client (less lags, smoother gameplay) Comfortable game WITHOUT donation! Episodes (open new content step by step to keep balance)
                  </p>
                  <button 
                    style={{ backgroundImage: `url(${Btn3})` }}
                    className="bg-cover h-24 w-52 bg-center bg-opacity-70 py-2 px-4 rounded-md hover:shadow-lg hover:scale-105 transition-all duration-300 ease-in-out">
                    <Link to="/serverinfo">Full Description</Link>
                  </button>
                </div>
              </div>

              {/* General Info Section */}
              <div className="bg-black bg-opacity-85 text-white p-6 w-full sm:w-1/2 rounded-md shadow-lg">
                <h2 className="text-2xl font-bold mb-4">New x10 Server Soon in 2025!</h2>
                <div className="border border-gray-600 p-4 rounded-md">
                  <h3 className="text-xl font-semibold">CLASSIC INTERLUDE INFO</h3>
                  <p className="mb-2">
                    Get ready for our new x10 Classic Interlude server, launching in 2025! This server will have everything you love from the x50 experience and much more, with enhanced features and customizations to elevate your gameplay.
                  </p>
                  <button 
                    style={{ backgroundImage: `url(${Btn3})` }}
                    className="bg-cover h-24 w-52 bg-center bg-opacity-70 py-2 px-4 rounded-md hover:shadow-lg hover:scale-105 transition-all duration-300 ease-in-out">
                    <Link to="/news">View All News</Link>
                  </button>
                </div>
              </div>

            </div>
          </div>


        </div>

        {/* Fixed Social Media Icons Section */}
        <div className="fixed top-2/3 right-6 flex flex-col space-y-6 z-20">
          <a href="https://discord.com/your-link" target="_blank" rel="noopener noreferrer">
            <FaDiscord className="text-white text-4xl hover:text-[#7289da] transition duration-200" />
          </a>
          <a href="https://youtube.com/your-channel" target="_blank" rel="noopener noreferrer">
            <FaYoutube className="text-white text-4xl hover:text-[#FF0000] transition duration-200" />
          </a>
          <a href="https://facebook.com/your-page" target="_blank" rel="noopener noreferrer">
            <FaFacebook className="text-white text-4xl hover:text-[#1877F2] transition duration-200" />
          </a>
        </div>
      </section>
    </Layout>
  );
}

export default Home;
