// src/pages/Dashboard.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Sidebar from './Sidebar';
import DashboardContent from './DashboardContent';
import Statistics from './Statistics';
import Donation from './Donation';
import Layout from '../Layout';
import Login from './Login';
import Register from './Register';

function Dashboard() {
  return (
    <Layout>
        <div className="flex">
        <Sidebar />
        <main className="flex-1 pt-40 p-6">
            <Routes>
            <Route path="/" element={<DashboardContent />} />
            <Route path="/statistics" element={<Statistics />} />
            <Route path="/donation" element={<Donation />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            </Routes>
        </main>
        </div>
    </Layout>
  );
}

export default Dashboard;
