import React from 'react';
import Button from './Button';

function DashboardContent() {
  return (
    <div className="relative">
      {/* Button Row */}
      <div className="absolute right-0 flex flex-col space-y-4 mt-4 mr-4 top-[-50px]">
        <Button to="/dashboard/statistics">Statistics</Button>
        <Button to="/dashboard/donate">Donate</Button>
      </div>

      {/* Dashboard Content */}
      <h1 className="text-3xl font-bold text-goldAccent">Welcome to the Dashboard</h1>
      <p className="mt-4">Here you can find a summary of the platform status and activity.</p>

      {/* Streamer Boxes */}
      <div className="mt-10">
        <h2 className="text-2xl font-semibold mb-4 text-goldAccent">Featured Streamers</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6">
          {/* Streamer 1 Box */}
          <div className="p-4 bg-gray-800 text-white rounded-lg shadow-lg flex flex-col items-center">
            <div className="w-full h-48 bg-gray-700 rounded-lg mb-4">
              {/* Placeholder for Streamer 1's stream thumbnail */}
              <p className="text-center text-gray-400 mt-20">Streamer 1 Thumbnail</p>
            </div>
            <h3 className="text-lg font-semibold">Streamer 1</h3>
            <p className="text-sm text-gray-400">Description or status for Streamer 1</p>
          </div>
          
          {/* Streamer 2 Box */}
          <div className="p-4 bg-gray-800 text-white rounded-lg shadow-lg flex flex-col items-center">
            <div className="w-full h-48 bg-gray-700 rounded-lg mb-4">
              {/* Placeholder for Streamer 2's stream thumbnail */}
              <p className="text-center text-gray-400 mt-20">Streamer 2 Thumbnail</p>
            </div>
            <h3 className="text-lg font-semibold">Streamer 2</h3>
            <p className="text-sm text-gray-400">Description or status for Streamer 2</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardContent;
