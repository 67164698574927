// src/components/Register.js
import React, { useState } from 'react';

function Register() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleRegister = (e) => {
    e.preventDefault();
    // Add registration logic here
  };

  return (
    <div className="h-4/5 w-4/5 flex items-center justify-center bg-black bg-opacity-65 rounded">
      <div className="w-full max-w-sm p-8 bg-gray-800 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-center text-goldAccent">Register</h2>
        <form onSubmit={handleRegister} className="mt-6 space-y-4">
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="w-full p-3 rounded bg-gray-700 text-white"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-3 rounded bg-gray-700 text-white"
          />
          <button type="submit" className="w-full p-3 bg-goldAccent text-gray-900 rounded font-bold">
            Register
          </button>
        </form>
      </div>
    </div>
  );
}

export default Register;
