import React, { useEffect } from 'react';
import NavBar from "./Navbar";
import Footer from "./Footer";

import bgImage from '../assets/bg_2.jpg';

function Layout({ children }) {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <div className="flex flex-col min-h-screen relative bg-cover bg-center bg-no-repeat"
            style={{ backgroundImage: `url(${bgImage})` }}>
            <NavBar />
            <main className="flex-grow">{children}</main>
            <Footer />
        </div>
    );
}

export default Layout;
