import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord, faYoutube, faFacebook } from '@fortawesome/free-brands-svg-icons';
import Logo from '../assets/Original.png'; // Update the path to your logo
import CatImg from '../assets/dzeus/cat.png'; // Path to your cat image
import CatImg2 from '../assets/dzeus/cat2.png'; // Path to your cat image
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="relative bg-black text-white py-10 px-10">
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 text-center md:text-left pl-0 md:pl-12 lg:pl-44">
        
        {/* About Section */}
        <div className="flex flex-col justify-between">
          <h3 className="text-goldAccent font-semibold text-lg mb-4">L2 Destiny</h3>
          <p>Home of Lineage 2 Interlude</p>
          <div className="flex justify-center md:justify-start space-x-4 mt-4">
            <a href="#" className="hover:text-[#7289da]">
              <FontAwesomeIcon icon={faDiscord} className="h-5 w-5" />
            </a>
            <a href="#" className="hover:text-[#FF0000]">
              <FontAwesomeIcon icon={faYoutube} className="h-5 w-5" />
            </a>
            <a href="#" className="hover:text-[#1877F2]">
              <FontAwesomeIcon icon={faFacebook} className="h-5 w-5" />
            </a>
          </div>
        </div>

        {/* Servers Section */}
        <div className="flex flex-col justify-between">
          <h3 className="text-goldAccent font-semibold text-lg mb-4">Servers</h3>
          <ul className="space-y-2">
            <li>
              <Link to="/serverinfo" className="text-linkAccent hover:text-linkHover">
                Carnage x50
              </Link>
            </li>
            <li>
              <Link to="/notfound" className="text-linkAccent hover:text-linkHover">
                AOBA x10
              </Link>
            </li>
          </ul>
        </div>

        {/* Documentation Section */}
        <div className="flex flex-col justify-between">
          <h3 className="text-goldAccent font-semibold text-lg mb-4">Documentation</h3>
          <ul className="space-y-2">
            <li><a href="/rulez" className="hover:text-redmoonRed">Server Rules</a></li>
            <li><a href="/rulez" className="hover:text-redmoonRed">Privacy Policy</a></li>
          </ul>
        </div>
      </div>

      {/* Logo Section */}
      <div className="flex flex-row justify-center mt-8">
        {/* Cat image positioned on the right side */}
      <img 
        src={CatImg} 
        alt="Cat" 
        className="absolute bottom-1 right-[-20px] w-36 lg:w-60 lg:right-[50px]" 
      />
      <img 
        src={CatImg2} 
        alt="Cat" 
        className="absolute bottom-1 left-[-20px] w-36 lg:w-60 lg:right-[50px]" 
      />
      <Link to="/">
        <img src={Logo} alt="L2 Destiny Logo" className="h-16" />
      </Link>
      </div>

      <div className="text-center mt-4 border-t border-gray-700 pt-4">
        <p>&copy; 2024 L2 Destiny. All rights reserved.</p>
      </div>

    </footer>
  );
}

export default Footer;
