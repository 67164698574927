// src/components/NewsSection.js
import React from 'react';
import Layout from './Layout';

const newsData = [
  {
    image: './home_img_1.jpg', // Update with the actual image path
    tag: 'Update',
    type: 'Essence x1',
    date: 'October 17, 2024',
    title: 'Changelog Essence',
    description: 'Read more about the last changes and fixes done in Reborn Essence server.',
  },
  {
    image: './home_img_1.jpg', // Update with the actual image path
    tag: 'Update',
    type: 'Essence x1',
    date: 'October 16, 2024',
    title: 'Changelog Essence',
    description: 'Read more about the last changes and fixes done in Reborn Essence server.',
  },
  {
    image: './home_img_1.jpg', // Update with the actual image path
    tag: 'Update',
    type: 'Essence x1',
    date: 'October 9, 2024',
    title: 'Changelog Essence',
    description: 'Read more about the last changes and fixes done in Reborn Essence server.',
  },
  {
    image: './home_img_1.jpg', // Update with the actual image path
    tag: 'Event',
    type: 'Essence x1',
    date: 'October 10, 2024',
    title: 'Legend of the Water Temple',
    description: 'An exciting event awaits with challenges and rewards in the Reborn Essence server.',
  },
  {
    image: './home_img_1.jpg', // Update with the actual image path
    tag: 'Announce',
    type: 'Forever H5 x15',
    date: 'October 5, 2024',
    title: 'Server Stages Information',
    description: 'Get all the details about the server stages and features for Forever H5 x15.',
  },
];

function News() {
  return (
<Layout>
    <section id="news" className="text-white bg-cover bg-center bg-no-repeat">
      <div className="max-w-7xl mx-auto md:px-4 pt-20">
        <h2 className="text-4xl font-semibold mb-8 text-center text-goldAccent">Latest News</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {newsData.map((news, index) => (
              <div key={index} className="bg-black rounded-lg overflow-hidden shadow-md bg-opacity-75">
              <div className="relative">
                <img src={news.image} alt={news.title} className="w-full h-48 object-cover" />
                <span className={`absolute top-2 left-2 bg-yellow-500 text-xs font-bold px-2 py-1 rounded-md`}>
                  {news.tag}
                </span>
              </div>
              <div className="p-4">
                <div className="text-xs text-gray-400 mb-1">{news.type}</div>
                <div className="text-sm text-gray-400 mb-2">{news.date}</div>
                <h3 className="text-lg font-semibold mb-2">{news.title}</h3>
                <p className="text-gray-300">{news.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
</Layout>
  );
}

export default News;
