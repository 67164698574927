import React, { useEffect, useState } from 'react';
import axios from 'axios';

const classNames = {
    0: 'Human Fighter',
    1: 'Warrior',
    2: 'Gladiator',
    3: 'Warlord',
    4: 'Human Knight',
    5: 'Paladin',
    6: 'Dark Avenger',
    7: 'Rogue',
    8: 'Treasure Hunter',
    9: 'Hawkeye',
    10: 'Human Mystic',
    11: 'Human Wizard',
    12: 'Sorcerer',
    13: 'Necromancer',
    14: 'Warlock',
    15: 'Cleric',
    16: 'Bishop',
    17: 'Prophet',
    18: 'Elven Fighter',
    19: 'Elven Knight',
    20: 'Temple Knight',
    21: 'Sword Singer',
    22: 'Elven Scout',
    23: 'Plains Walker',
    24: 'Silver Ranger',
    25: 'Elven Mystic',
    26: 'Elven Wizard',
    27: 'Spellsinger',
    28: 'Elemental Summoner',
    29: 'Elven Oracle',
    30: 'Elven Elder',
    31: 'Dark Fighter',
    32: 'Palus Knight',
    33: 'Shillien Knight',
    34: 'Bladedancer',
    35: 'Assassin',
    36: 'Abyss Walker',
    37: 'Phantom Ranger',
    38: 'Dark Mystic',
    39: 'Dark Wizard',
    40: 'Spellhowler',
    41: 'Phantom Summoner',
    42: 'Shillien Oracle',
    43: 'Shillien Elder',
    44: 'Orc Fighter',
    45: 'Orc Raider',
    46: 'Destroyer',
    47: 'Monk',
    48: 'Tyrant',
    49: 'Orc Mystic',
    50: 'Orc Shaman',
    51: 'Overlord',
    52: 'Warcryer',
    53: 'Dwarf Fighter',
    54: 'Scavenger',
    55: 'Bounty Hunter',
    56: 'Artisan',
    57: 'Warsmith',
    // Third Classes
    88: 'Duelist',
    89: 'Dreadnought',
    90: 'Phoenix Knight',
    91: 'Hell Knight',
    92: 'Sagittarius',
    93: 'Adventurer',
    94: 'Archmage',
    95: 'Soultaker',
    96: 'Arcana Lord',
    97: 'Cardinal',
    98: 'Hierophant',
    99: "Eva's Templar",
    100: 'Sword Muse',
    101: 'Wind Rider',
    102: 'Moonlight Sentinel',
    103: 'Mystic Muse',
    104: 'Elemental Master',
    105: "Eva's Saint",
    106: 'Shillien Templar',
    107: 'Spectral Dancer',
    108: 'Ghost Hunter',
    109: 'Ghost Sentinel',
    110: 'Storm Screamer',
    111: 'Spectral Master',
    112: 'Shillien Saint',
    113: 'Titan',
    114: 'Grand Khavatari',
    115: 'Dominator',
    116: 'Doom Cryer',
    117: 'Fortune Seeker',
    118: 'Maestro',
};

const formatTime = (seconds) => {
    const days = Math.floor(seconds / 86400);
    const hours = Math.floor((seconds % 86400) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    
    const parts = [];
    if (days > 0) parts.push(`${days} d`);
    if (hours > 0) parts.push(`${hours} h`);
    parts.push(`${minutes} m`);
    
    return parts.join(' ');
};

const Statistics = () => {
    const [playerStats, setPlayerStats] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [sortKey, setSortKey] = useState('pvp'); // Default sort key
    const [sortOrder, setSortOrder] = useState('desc'); // Default sort order

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get('http://localhost:8080/api/stats/top-pvp');
            setPlayerStats(response.data);
        } catch (error) {
            setError('Error fetching data: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Sort player stats based on the selected criteria
    const handleSort = (key) => {
        const orderModifier = sortKey === key && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortKey(key);
        setSortOrder(orderModifier);
    };

    const sortedStats = [...playerStats].sort((a, b) => {
        const orderModifier = sortOrder === 'asc' ? 1 : -1;
        if (a[sortKey] < b[sortKey]) return -1 * orderModifier;
        if (a[sortKey] > b[sortKey]) return 1 * orderModifier;
        return 0;
    });

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Ratings L2 Destiny</h1>
            {loading && <p className="text-lg">Loading...</p>}
            {error && <p className="text-red-500">{error}</p>}
            {playerStats.length === 0 ? (
                <p>No data available</p>
            ) : (
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-gray-800 bg-opacity-35 border border-goldAccent text-sm"> {/* Reduced font size */}
                        <thead>
                            <tr className="bg-gray-800 border-b">
                                <th className="text-left py-2 px-2">ID</th> {/* Reduced padding */}
                                <th className="text-left py-2 px-2">Character Name</th>
                                <th className="text-left py-2 px-2 cursor-pointer" onClick={() => handleSort('pvp')}>
                                    PvP Kills {sortKey === 'pvp' && (sortOrder === 'asc' ? '▲' : '▼')}
                                </th>
                                <th className="text-left py-2 px-2 cursor-pointer" onClick={() => handleSort('pk')}>
                                    PK Kills {sortKey === 'pk' && (sortOrder === 'asc' ? '▲' : '▼')}
                                </th>
                                <th className="text-left py-2 px-2 cursor-pointer" onClick={() => handleSort('time')}>
                                    Online Time {sortKey === 'time' && (sortOrder === 'asc' ? '▲' : '▼')}
                                </th>
                                <th className="text-left py-2 px-2">Class Name</th>
                                <th className="text-left py-2 px-2">Clan ID</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedStats.slice(0, 50).map((player, index) => (
                                <tr key={player.objId} className="border-b hover:bg-gray-800 hover:bg-opacity-45">
                                    <td className="py-2 px-2">{index + 1}</td> {/* Reduced padding */}
                                    <td className="py-2 px-2">{player.charName}</td>
                                    <td className="py-2 px-2">{player.pvp}</td>
                                    <td className="py-2 px-2">{player.pk}</td>
                                    <td className="py-2 px-2">{formatTime(player.time)}</td> {/* Format the time */}
                                    <td className="py-2 px-2">{classNames[player.classId] || 'Unknown'}</td>
                                    <td className="py-2 px-2">{player.clanId}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default Statistics;
