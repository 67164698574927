// src/components/Sidebar.js
import React from 'react';
import { Link } from 'react-router-dom';

function Sidebar() {
  return (
    <aside className="w-64 bg-gray-800 p-4 shadow-lg pt-40 h-screen">
      <h2 className="text-xl font-bold text-goldAccent mb-6">Admin Panel</h2>
      <nav className="space-y-4">
        <Link to="/dashboard" className="block text-white hover:text-goldAccent">Dashboard</Link>
        <Link to="/dashboard/statistics" className="block text-white hover:text-goldAccent">Statistics</Link>
        <Link to="/dashboard/donation" className="block text-white hover:text-goldAccent">Donation</Link>
        <Link to="/dashboard/login" className="block text-white hover:text-goldAccent">Login</Link>
        <Link to="/dashboard/register" className="block text-white hover:text-goldAccent">Register</Link>
      </nav>
    </aside>
  );
}

export default Sidebar;
