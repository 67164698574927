// src/pages/Rules.js

import React from 'react';
import Layout from './Layout';

const Rules = () => {
  return (
    <Layout>

    <div className="max-w-4xl mx-auto p-6 text-gray-400 pt-32">
      <h1 className="text-3xl font-bold text-center mb-8">L2Destiny.com Rules</h1>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Terms and Conditions of Digital Product Sales</h2>
        <p>By purchasing digital products on this site, you agree to these terms and enter a contract with L2Destiny, located in Romania. VAT may apply based on your location within the EU.</p>
        
        <h3 className="text-xl font-semibold mt-6">Return, Refund, and Cancellation Policy</h3>
        <p>Refunds on digital products are generally not available. In rare cases of special circumstances, please contact us to review your request. Note that any refunds will revoke access to the digital product.</p>
        
        <h3 className="text-xl font-semibold mt-6">Delivery Policy</h3>
        <p>Digital products purchased from our store are accessible via L2Destiny.com.</p>

        <h3 className="text-xl font-semibold mt-6">Customer Inquiries</h3>
        <p>If you have any questions, please email <a href="mailto:support@l2destiny.com" className="text-blue-500 underline">support@l2destiny.com</a>.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Privacy Policy</h2>
        <p>Your privacy is highly important to us. We do not share or sell your personal information. We may contact you directly about your purchases or occasionally with updates if you opted in. Visit our privacy page for more details.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Rules and Guidelines</h2>

        <h3 className="text-xl font-semibold mt-6">Account and Software Usage</h3>
        <ul className="list-disc list-inside">
          <li>
            <strong>Third-Party Software:</strong> Usage of any unauthorized software, including bots and macros, is prohibited.  
            <span className="text-red-600"> Penalty:</span> Account ban, with no possibility of reversal for repeated offenses.
          </li>
          <li>
            <strong>Account Sharing:</strong> Sharing account details is allowed but at your own risk. No support will be provided for lost items or fraud due to account sharing.
          </li>
        </ul>

        <h3 className="text-xl font-semibold mt-6">Bugs and Exploits</h3>
        <p>Active exploitation of bugs or unintended game mechanics is strictly prohibited.</p>
        <p><span className="text-red-600">Penalty:</span> Up to a full project ban.</p>

        <h3 className="text-xl font-semibold mt-6">Chatting and Names</h3>
        <ul className="list-disc list-inside">
          <li><strong>Offensive Language:</strong> Slander, racism, real-life threats, and other disruptive behavior are forbidden.</li>
          <li><strong>Impersonation:</strong> Impersonating staff members in any way is prohibited.</li>
          <li><strong>Indecent Names:</strong> Offensive or inappropriate names are not allowed.</li>
        </ul>

        <h3 className="text-xl font-semibold mt-6">Trading Rules</h3>
        <ul className="list-disc list-inside">
          <li>Setting up shops near important NPCs or blocking pathways is prohibited.</li>
          <li>Selling or buying items/accounts for real currency is strictly forbidden.</li>
          <li>Fake shops intended to deceive players are prohibited.</li>
        </ul>

        <h3 className="text-xl font-semibold mt-6">GM Events</h3>
        <p>Uninvited interference in GM events is forbidden. Only enter event zones when invited or if the entrance is open to everyone.</p>

        <h3 className="text-xl font-semibold mt-6">Lost or Stolen Items</h3>
        <p>We do not restore lost or stolen items. Please avoid sharing your account to protect your items.</p>

        <h3 className="text-xl font-semibold mt-6">Clan and Epic Item Rules</h3>
        <p>Clan leaders may request back Epic items given temporarily to members if proper agreements were made and documented with screenshots. Without these agreements, no items will be returned.</p>

        <h3 className="text-xl font-semibold mt-6">Staff Members</h3>
        <p>Disrespectful behavior toward Staff Members is prohibited. This includes all Administrators (Admins) and Game Masters (GMs) both in-game and on forums or social channels.</p>
        <p><span className="text-red-600">Penalty:</span> Ranging from temporary bans to a permanent ban on the user’s account.</p>
      </section>
    </div>
    </Layout>
  );
};

export default Rules;
