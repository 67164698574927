// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import ServerInfo from './components/ServerInfo';
import News from './components/News';
import NotFound from './components/NotFound';
import Rulez from './components/Rulez';
import Download from './components/Download';
import Support from './components/Support';
import Dashboard from './components/dashboard/Dashboard';


function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/serverinfo' element={<ServerInfo />} />
        <Route path='/news' element={<News />} />
        <Route path='/download' element={<Download />} />
        <Route path='/support' element={<Support />} />
        <Route path="/dashboard/*" element={<Dashboard />} />
        <Route path='/rulez' element={<Rulez />} />
        <Route path='/notfound' element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
