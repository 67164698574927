// src/components/404.js
import React from 'react';
import { Link } from 'react-router-dom';
import Layout from './Layout';
import NotFoundImage from './img/404.png'; // Add an image for more style


function NotFound() {
  return (
    <Layout>
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-900 text-white">
          <img src={NotFoundImage} alt="404 Not Found" className="w-64 mb-8" />
          <h1 className="text-9xl font-bold text-redmoonRed">404</h1>
          <h2 className="text-4xl font-semibold mb-4">Page Not Found</h2>
          <p className="text-gray-400 text-lg mb-8">Oops! The page you're looking for doesn't exist.</p>
          
          <Link to="/" className="bg-blue-600 text-white px-6 py-3 rounded-md text-lg hover:bg-blue-700 transition duration-300">
            Go Back to Home
          </Link>
        </div>
    </Layout>
  );
}

export default NotFound;
