import React from 'react';
import './ServerStatus.css'; // Separate CSS for animations
// import SmallLogo from '../assets/small-logo3.png'; 
import SmallLogo from '../assets/dzeus/aoba.png'; // Path to the small round server logo
import ComingSoonImage from '../assets/coming-soon.png'; // Path to the "Coming Soon" image
import { Link } from 'react-router-dom';

function ServerStatus() {
  return (
    <div className="server-status-container relative border border-opacity-45 border-goldAccent bg-black bg-opacity-60 p-4 rounded-lg shadow-lg flex items-center space-x-4">
      {/* Coming Soon Image in Top Right Corner */}
      <img src={ComingSoonImage} alt="Coming Soon" className="coming-soon-badge" />

      {/* Small Round Logo with Beating Animation */}
      <Link to="/notfound">
        <div className="logo-beat-container relative">
          <img src={SmallLogo} alt="Server Logo" className="server-logo2 animate-beat" />
          <div className="particle-glow2"></div>
        </div>
      </Link>

      {/* Server Status Info - Text on the right */}
      <div className="status-info text-right">
        <h2 className="text-3xl font-extrabold text-white">AOBA</h2>
        <div className="flex items-center space-x-2">
          <div className="status-dot2 bg-redmoonRed"></div>
          <span className="text-lg font-bold text-redmoonRed">Offline</span>
        </div>
        <div className="text-lg font-semibold text-goldAccent">Server: x10</div>
      </div>
    </div>
  );
}

export default ServerStatus;
